.wysiwyg-content-large
  font-size: 32px
  line-height: 48px

.wysiwyg-content-medium
  font-size: 27px
  line-height: 40px

.wysiwyg-content-normal
  line-height: 1.75rem !important

.wysiwyg-content
  text-align: left

  h2
    font-size: 40px
    line-height: 56px
    font-weight: bold
    margin-bottom: 16px

  h3
    font-size: 27px
    line-height: 2
    margin-bottom: 16px

  h4
    font-size: 20px
    line-height: 32px
    margin-bottom: 16px

  @media (max-width: 768px)
    h2
      font-size: 27px
      line-height: 32px

    h3
      font-size: 22px
      line-height: 32px

    h4
      font-size: 18px
      line-height: 32px

  ol, ul
    list-style-position: inside

  ol
    list-style-type: decimal

  ul
    list-style-type: disc

  a
    text-decoration: underline

  .ql-align-left
    text-align: left

  .ql-align-center
    text-align: center

  .ql-align-right
    text-align: right

  .ql-align-justify
    text-align: justify

.ql-container
  min-height: 82px
